var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import TaxonomySelection from "../../search/components/TaxonomySelection";
var ReviewTaxonomySelection = /** @class */ (function (_super) {
    __extends(ReviewTaxonomySelection, _super);
    function ReviewTaxonomySelection() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.updateState = function (value) {
            _this.setState({ selectedOptions: value }, function () {
                var _a;
                var values = _this.state.selectedOptions.map(function (option) { return option.name; });
                (_a = _this.props) === null || _a === void 0 ? void 0 : _a.onSelected(_this.props.refinementSlug, values);
            });
        };
        return _this;
    }
    return ReviewTaxonomySelection;
}(TaxonomySelection));
export default ReviewTaxonomySelection;
